@import "src/assets/stylesheets/variables";

.newsDetails {
  margin: 20px 150px;
  //color: $black;
  .animated,
  .c-title {
    width: auto;
    font-size: 32px;
    transform: none;
    color: $black;
    position: static;
    text-align: left;
    margin: 50px 0px;
    //margin: 40px auto;

    //display: inline-block;
  }
  .c-image {
    //margin: 0 10vw;
  }
  .c-date {
    font-size: 16px;
    display: inline-block;
    margin-top: 20px;
  }
  .c-description {
    text-align: left;
  }

  .centerTitle {
    h2 {
      font-size: 16px;
    }
    text-align: center;
  }
  .shareNews {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    color: black;
    li {
      width: 20px;
      margin: 0px 15px;
      path {
        fill: #a2a2a2;
      }
      &:hover {
        path {
          fill: $black;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .newsDetails {
    margin: 10vw 15vw;
    //.c-image {
    //  margin: 0 13.5vw;
    //}
  }
}
