body {
  color: $text-color;
  font-family: "Montserrat", "Noto Sans Arabic", Arial, Helvetica Neue, Helvetica, sans-serif;
}

h1, .h1 {
  font-size: 56px;
  line-height: 56px;
  font-weight: 700;
}

h2, .h2 {
  width: auto;
  font-size: 40px;
  line-height: 40px;
  font-weight: 600;
}

h3, .h3 {
  font-size: 32px;
  line-height: 42px;
}

h4, .h4 {
  font-size: 30px;
  line-height: 40px;
}

h5, .h5 {
  font-size: 25px;
  line-height: 35px;
}

h6, .h6 {
  font-size: 16px;
  line-height: 30px;
}

a.MuiLink-root {
  cursor: pointer;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: 100%;
}

.text-underline {
  border-bottom: 1px solid #fff;
  padding-bottom: 2px;
}

.pre-line {
  white-space: pre-line;
}

ul {
  list-style-type: none;
  margin-bottom: 0;
}

//.MuiButton-root.MuiButton-containedPrimary {
//  color: $white;
//  background-color: $primary;
//}
@media (max-width: 991px) {
  h1, .h1 {
    font-size: 8.8888888889vw;
  }
  h2, .h2 {
    font-size: 6.111111vw;
  }
  h5, .h5 {
    font-size: 4.722222vw;
  }
}
