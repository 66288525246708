@import "src/assets/stylesheets/variables";

.c-layout {
  background-color: #fff;
  &.ar-ps {
    direction: rtl;

    .c-data-list .c-description,
    .newsDetails .c-title,
    .c-contact .c-form-container .c-title,
    .c-contact .c-form-container .c-description {
      text-align: right;
    }

    .c-contact .c-form {
      direction: rtl;

      label {
        left: unset;
      }
    }

    .c-list.column-layout {
      direction: ltr;

      .c-item {
        direction: rtl;
      }
    }

    .language-container > button {
      margin-left: 0;
      margin-right: 24px;
    }

    @media (max-width: 991px) {
      .header-menu {
        direction: ltr;
      }
    }
  }

  &.en-ps {
    direction: ltr;
  }
}

.c-public-layout {
  margin-top: $header-height;

  .c-title {
    width: 72.994vw;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  .c-description {
    margin-top: 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 28px;
  }
}

@media (max-width: 991px) {
  .c-public-layout {
    .c-title {
      margin-top: 13.888889vw;
      width: 80.694444vw;
      max-width: 100%;
    }
    .c-description {
      margin-top: 30px;
      margin-bottom: 50px;
    }
  }
}
