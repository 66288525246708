@import "src/assets/stylesheets/variables";

.c-address {
  color: $grey3;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;

  .c-title {
    font-weight: 700;
    display: flex;
    padding-left: 0 !important;
    text-align: left;
  }

  .c-info {
    font-weight: 400;
    display: flex;
    text-align: right;
  }
  .c-link {
    text-decoration: underline;
    color: #b23529;
  }
}
