//primary colors
$primary: #32c1c1 !default;

//secondary colors
$secondary: #485b6f !default;

// scss-docs-start gray-color-variables
$white:    #ffffff !default;
$grey:    #4c4c4c !default;
$grey1:    #999999 !default;
$grey2:    #f3f3f3 !default;
$grey3:    #717171 !default;
$gray-100: #f8f9fa !default;
$gray-500: #cccccc !default;
$gray-600: #718096 !default;
$gray-900: #485b6f !default;
$black:    #000000 !default;
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #ef5350 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
$red-100: #B23529 !default;

$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;

$text-color: $white;
$header-bg: rgba(37, 39, 40, 0.8);
$footer-bg: #252728;
//Fonts
//$main-font: 'CentraNo2-Light';
//$light-font: 'CentraNo2-Light';

$header-height: 64px !default;
