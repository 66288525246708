@keyframes fadeInUp {
  0% {
     opacity: 0;
     transform: translate3d(0, 70%, 0);
   }
  100% {
       opacity: 1;
       transform: translateZ(0);
     }
}

.fadeInUp {
  animation-name: fadeInUp;
}

.animated {
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
