.text-widget {
  padding: 1rem;
  width: 100%;
  background: linear-gradient(
    135deg,
    rgb(141 141 141 / 80%),
    rgba(40, 40, 40, 0.8)
  );
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  color: black;
  text-align: center;

  h2 {
    margin-bottom: 0.5rem;
  }

  p {
    margin: 0;
  }
}
