@import "src/assets/stylesheets/variables";

.c-image-with-horizantal-blocks {
  position: relative;
  width: 100%;
  color: white;

  .c-image {
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
      height: auto;
      width: 100%;
    }
  }

  .c-content {
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }

  .c-list {
    padding: 42px;
    width: 100%;

    .c-item_container {
      padding-top: 12px;
      padding-bottom: 12px;
    }

    .c-item {
      border: 1px solid white;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      color: $text-color;
      max-width: 320px;
      height: 100%;

      .item-icon {
        margin: 20px 0 20px 0;
        width: 5.208333vw;
        padding: 5px;

        img {
          height: auto;
        }
      }
      .item-title {
        margin: 20px 0 20px 0;
        text-align: center;
      }
      .item-img {
        padding: 5px;

        img {
          height: auto;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .c-image-with-horizantal-blocks {
    .item-icon {
      margin: 20px 0 20px 0;
      width: 9.722222vw !important;
    }

    .c-content .c-list {
      position: absolute;
      top: calc(50% + 70px);
      left: 53%;
      transform: translate(-50%, -50%);
    }
  }
}
