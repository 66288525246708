@import "src/assets/stylesheets/variables";

.c-carousel-video {
  position: relative;
  width: 100%;

  .c-image {
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .c-title {
    position: absolute;
    top: 110px;
    left: 50%;
    transform: translateX(-50%);
  }
  .c-link {
    position: absolute;
    //width: 100%;
    bottom: 64px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    display: flex;
    justify-content: center;

    .MuiLink-root {
      color: #fff;
      background-color: transparent;
      border-color: #fff;
      box-shadow: inset 0 0 0 1px #fff;
      padding: 12px 24px;
      margin: 0 33px;
      width: 100%;
      min-width: 200px;
      max-width: 600px;
    }
  }
}