@import "src/assets/stylesheets/variables";

.c-image-with-vertical {
  position: relative;
  //min-height: 100vh;
  width: 100%;
  color: white;

  .c-image {
    height: 100%;
    width: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .c-list {
    margin-top: 110px;
    width: 80%;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &.row-layout {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      flex-wrap: wrap;
      height: calc(100% - 110px);

      .c-title {
        width: 100%;
      }

      .c-item {
        padding: 0 5% 0 5%;
        width: 25%;
        margin: auto;
        margin-bottom: 0;

        &:first-child {
          border: none !important;
        }
      }
    }

    .c-item {
      width: 65%;
      border-left: 2px solid white;
      padding-left: 10px;
      margin-top: 30px;
      align-items: end;

      .c-title {
        text-align: left;
      }

      .c-description {
        text-align: left;
        font-size: 16px;
        margin: 20px 0 20px 0;
      }
    }
  }
}

@media (max-width: 991px) {
  .c-image-with-vertical .c-list {
    position: static;
    transform: none;
    width: 68.888889vw;
    margin: 0 auto 14.583333vw auto;

    &.row-layout {
      display: block;
      .c-item {
        border: 0;
        width: 100%;

        .c-title,
        .c-description {
          text-align: center;
        }
      }
    }

    .c-item {
      border-color: $black;
      width: 100%;
      margin-bottom: 5px;

      .c-title {
        margin: 0;
      }
    }
  }
}
