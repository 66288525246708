@import "src/assets/stylesheets/variables";

.Models-Drawer .MuiPaper-root {
  padding-top: $header-height;
  z-index: 1099;
  background-color: $grey2;
}

.Nav-Drawer {
  .MuiPaper-root {
    z-index: 100;
    padding-top: $header-height;
    width: 100%;
    font-size: 4.4444444444vw;
    font-weight: 500;
  }
}

.c-header {
  height: $header-height;
  background-color: $header-bg;
  backdrop-filter: blur(20px);
  font-size: 16px;
  font-weight: 500;
  justify-content: center;
  text-transform: uppercase;

  .MuiToolbar-root {
    padding: 0 56px;
  }

  .MuiToggleButtonGroup-root .MuiToggleButton-root {
    color: $white;
    height: 34px;

    &.Mui-selected {
      color: $black;
      background-color: $white;
    }
    &:hover {
      color: $black;
      background-color: $white;
    }
  }

  .header-menu {
    width: 100%;
    white-space: nowrap;
  }

  .language-container {
    font-size: 14px;
  }

  .header-max-width {
    height: 20px;

    a,
    img {
      height: 20px;
      vertical-align: unset;
    }
  }

  @media (max-width: 335px) {
    .header-max-width {
      width: 49%;
    }
    .c-header {
      .MuiToolbar-root {
        padding: 0 32px;
      }
    }
  }
}

@media (max-width: 991px) {
  .Models-Drawer .MuiIconButton-root {
    margin: 20px auto 0 20px;
  }
  .c-header {
    .MuiToolbar-root {
      padding: 0 32px;
    }
  }
  .header-menu a span {
    text-transform: uppercase;
  }
}
