.sidebar {
  position: fixed;
  top: 80px;
  left: 28px;
  background: rgba(249, 249, 249, 0.9);
  border-radius: 8px;

  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.sidebar ul {
  list-style: none;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 20px;
}

.sidebar ul li {
  margin-bottom: 10px;
}

.sidebar ul li a {
  text-decoration: none;
  color: #6c757d;
  display: block;
  border-radius: 4px;
}

.toggle-button {
  position: absolute;
  top: -1px;
  width: 40px;
  background: transparent;
  color: #fff;
  border: none;
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    width: 30px;
    height: 30px;
  }

  &.close {
    color: #6c757d;
  }

  &.open {
    color: #6c757d;
  }
}

.close {
  padding: 1px;
  margin-left: -4px;
}

.sidebar ul li a.active {
  background: #6c757d;
  color: #fff;
}

@media (max-width: 767px) {
  .sidebar {
    top: 10px;
    left: 10px;
    right: 10px;
    width: calc(100% - 20px);
    padding: 10px;
    box-shadow: none;
    border-radius: 0;
  }

  .toggle-button {
    position: fixed;
    top: 10px;
    z-index: 1001;
  }
}
