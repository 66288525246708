@import "src/assets/stylesheets/variables";

.c-footer {
  background-color: $footer-bg;
  color: white;

  .footer-item a {
    text-transform: capitalize;
    text-align: center;
    margin: auto;
  }

  .text-lowercase {
    text-transform: lowercase !important;
  }

  .footer-menu {
    padding-left: 0;
    padding-right: 0;
    li {
      padding-left: 0;
      padding-right: 0;
    }

    margin: auto;
  }
  .c-footer_links {
    //color: $grey1;
    margin: 0 32px;
    padding: 40px 0;
    border-bottom: 1px solid $grey;
    .copyright,
    .footer-item li:hover {
      color: $grey1;
    }
  }

  .social-icons {
    width: 20px;
    height: 20px;
    margin: 0px 15px;
    fill: gray;

    &:hover {
      path {
        fill: $white;
      }
    }
  }
}
@media (max-width: 991px) {
  .footer-item .MuiLink-root {
    font-size: 17px;
  }
}

a[href^="mailto:"] {
  text-transform: lowercase !important;
}

.social-links-footer {
  span {
    margin-bottom: 20px;
  }
}
