.c-details-table {
  background-color: rgba(37, 39, 40, 0.8);
  border-radius: 0;
  padding: 16px;

  .MuiTable-root {
    width: 80%;
    margin: auto;
    border: 1px solid rgba(51, 51, 51, 0.8);

    .MuiTableHead-root {
      background-color: rgba(51, 51, 51, 0.8);
    }

    th,
    tr {
      background-color: rgba(51, 51, 51, 0.5) !important;
    }

    .MuiTableCell-root {
      border-bottom: unset;
      text-align: center;
      color: #fff;
    }
  }
}
