@import "src/assets/stylesheets/variables";

.MuiTabs-root {
  width: 100%;
  color: $white;
}

.custom-tabs.lang-ar button {
  font-size: 1rem;
}
