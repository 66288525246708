@import "src/assets/stylesheets/variables";

.c-cover-image {
  position: relative;
  width: 100%;
  color: white;

  .c-image {
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .c-title {
    position: absolute;
    margin-top: 6.25vw;
    left: 50%;
    transform: translateX(-50%);
  }

  .blackText {
    color: $black;
  }

  .position-left {
    text-align: left;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
  }
  .c-link {
    position: absolute;
    //width: 100%;
    bottom: 64px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    display: flex;
    justify-content: center;

    @media (max-width: 767px) {
      bottom: 20%;
    }

    .MuiLink-root {
      color: #fff;
      background-color: transparent;
      border: 1px solid #fff;
      padding: 12px 24px;
      margin: 0 33px;
      width: 100%;
      min-width: 200px;
      max-width: 600px;
    }
  }

  .c-cover-image__plus_container {
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    z-index: 100;
    text-align: center;

    &.open {
      left: 0;
      right: 0;
      margin: auto;
      display: block;
      line-height: 100%;
      z-index: 100;
      transform: scale(1, 1) rotate(45deg);
      position: sticky;
      top: 27.777778vw;
      bottom: auto;

      .learn-more {
        display: none;
      }
    }

    .c-cover-image__plus_container__btn {
      width: 40px;
      height: 40px;
      background-color: #fff;
      border-radius: 50%;
      min-width: 42px;
      font-size: 38px;
      text-align: center;
      box-shadow: 1px 2px 10px -1px rgba(0, 0, 0, 0.57);

      &:hover {
        background-color: rgba(51, 51, 51, 0.8);
        color: #fff;
      }
    }
  }

  .c-data .c-cover-image .c-title {
    position: static;
    margin-top: 10px;
    transform: none;
  }

  @media (max-width: 767px) {
    .animated.c-push-position {
      position: relative;

      .c-title {
        position: relative;
        transform: initial;
        left: initial;
      }

      * {
        color: black !important;
      }
    }
  }
}
