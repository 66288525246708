@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.slick-slider {
  .slick-arrow {
    display: none;
  }
}

.slick-slider_content {
  padding-left: 20px;
  padding-right: 20px;
}
