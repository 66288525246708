@import "src/assets/stylesheets/variables";

.c-cover-image .c-form-container {
  //color: black;
  //font-size: 10px;
  position: relative;
  width: 100%;
  margin: 20px;
  .c-image {
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }
  .c-form{
    position: absolute;
    width: 40%;
    height: 80%;
    top: 10%;
    left: 15%;
    z-index: 1;
    border-radius: 10px;
    backdrop-filter: blur(17px) contrast(70%);
    overflow-y: scroll;
    padding: 10px;
    margin-bottom: 10px;
  }
  .field-title{
    margin: 20px;
    padding-right: 20px;
    display: inline-block;
    //width: 300px;
    width: 20%;
  }
  .form-field{
    margin: 10px 10px 15px 10px;
    display: inline-block;
    width: 100%;
    //width: 500px;
    input, label, svg, li, .MuiInputBase-root{
      color: $white;
    }
  }
  button{
    width: 100%;
    margin: 10px 10px 15px 10px;
  }
  .form-fields{
    width: 70%;
  }
}

//.c-error {
//  padding-bottom: 30px;
//  //padding-left: 30px;
//  width: 100%;
//  color: $red;
//}
//
//@media (max-width: 991px) {
//  .c-error{
//    padding-left: 30px;
//  }
//}
