@import "src/assets/stylesheets/variables";

.c-nav_img {
  position: relative;
  width: 100%;
  color: white;

  .c-image {
    height: 100%;
  }
}