@import "src/assets/stylesheets/variables";

.c-data-list {
  position: relative;
  //min-height: 100vh;
  //width: 100%;
  width: auto;

  .c-main-title {
    color: $black;
  }

  .c-title,
  .c-list,
  .c-content {
    color: $black;
    position: static;
    transform: none;
    margin-bottom: 50px;
    line-height: 1.375;
  }

  .c-description {
    line-height: 1.375;
    margin: auto !important;
  }

  .animated {
    position: static;
  }

  .c-data_container {
    background-color: #f3f3f3;
    padding: 0 0 60px 0;

    ul {
      list-style-type: disc;
      color: #717171;
      padding-left: 20px;

      li {
        padding-left: 10px;
        font-size: 16px;
        line-height: 19.25px;

        &::marker {
          color: red;
        }
      }
    }
  }

  .c-item {
    display: flex;
    flex-direction: column;
    color: $black;

    .c-title,
    .c-description {
      text-align: left;
    }

    .c-title {
      position: static;
      margin-top: 30px;
      transform: none;
      margin-bottom: 0px;
    }
  }
}

@media (max-width: 991px) {
  .c-data-list {
    .c-data {
      padding: 50px 24px 0 25px;
    }
    .c-item {
      .c-title,
      .c-description {
        margin: 3.472222vw 0 0;
        //font-size: 3.888889vw;
      }
    }
  }
}

.MuiCollapse-root .c-data-list .c-data_container {
  background-color: transparent;
  .c-item {
    .c-title,
    .c-description {
      text-align: center;
    }
    @media (max-width: 991px) {
      .c-title {
        font-size: 3.888889vw;
      }
    }
  }
}
