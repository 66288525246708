@import "src/assets/stylesheets/variables";

.c-models {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 25px;

  a {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    margin: 12px;
    border-radius: 10px;
    justify-content: center;
    text-align: center;

    &:hover {
      box-shadow: 0 10px 20px 0 rgba(39, 43, 50, 0.2);
    }

    div {
      padding: 20px 10px;
      text-align: center;
    }
    img {
      border-radius: 10px;
      width: 100%;
    }
  }

  @media screen and (max-width: 1023.98px) {
    li {
      flex: 0 0 50%;
      max-width: 100%;
    }
    justify-content: start;
  }
}
.car-item {
  text-decoration: none;
  color: #635a5a;
}
