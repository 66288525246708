.Loading {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: 30px 0;
  height: 100vh;
  width: 100vw;

  .small {
    width: 100px;
  }
}
