@import "src/assets/stylesheets/variables";

.c-grid {
  position: relative;
  width: 100%;
  color: white;

  .c-image {
    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }
  }

  .c-title {
    position: static;
    margin-top: 10px;
    transform: none;
  }

  .blackText{
    color: $black;
  }
}

